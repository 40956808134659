
import { Component, Vue, Watch } from 'vue-property-decorator'
import AddDeviceBasic from '@/components/imagetru3d/add-device-basic.vue'
import AddHardware from '@/components/imagetru3d/add-hardware.vue'
import AddVirtualCamera from '@/components/imagetru3d/add-virtual-camera.vue'
import AddActiveInfo from '@/components/imagetru3d/add-active-info.vue'
import {
  apiDeviceAdd,
  apiDeviceDetail,
  apiDeviceEdit,
  apiDeviceNumber,
  apiGetDeviceCategory,
  getDeviceSizeApi,
  getDeviceSupplierApi
} from '@/api/imagetru3d'

    @Component({
      components: {
        AddActiveInfo,
        AddDeviceBasic,
        AddHardware,
        AddVirtualCamera
      }
    })
export default class DeviceEdit extends Vue {
        $refs!: { form: any }
        // 设备 id
        id!: any
        // 默认不加载页面
        loading = true
        // 默认界面
        activeName = 'deviceBasic'
        // 用于存储获取到的设备分类列表
        deviceCategoryList = []
        sizeList = []
        supplierList = []

        // 表单
        // 设备基础信息页表单
        form: any = {
          // 设备名称
          device_name: '',
          // 设备型号
          device_number: '',
          // 设备尺寸类型
          device_size_type: '',
          // 自定义设备尺寸
          device_size: '',
          // 设备类型
          category_id: '',
          // 设备备注
          mask: '',
          note: '',
          // 分类图片
          device_img: '',
          supplier_time: '',
          supplier_name: '',
          supplier_id: 0,
          color: '', // 设备颜色
          id: 0,
          merchant_mobile: ''// 所属机构
        }

        // 与硬件相关的参数，绑定的数据
        hardwareSpecData: any = {
          hardware_spec_item: []
        }

        // 与虚拟相机相关的参数，绑定的数据
        virtualCameraSpecData: any = {
          virtualCamera_spec_item: []
        }

        // 必填字段与对应的选项卡，为了实现规则验证
        requireFields = [
          {
            fields: [
              /* 'device_name', */
              'device_number',
              'device_size',
              /* 'category_id', */
              // 'note',
              /*  'device_img', */
              'supplier_time', // 出厂日期
              'supplier_name', // 生产厂家
              'merchant_mobile'
            ],
            activeName: 'deviceBasic'
          }
        ]

        // 规则验证
        rules = {

          device_number: [
            {
              required: true,
              message: '请输入设备型号',
              trigger: ['blur', 'change']
            }
          ],
          merchant_mobile: [
            {
              required: false,
              message: '请填写正确的机构手机号',
              trigger: ['blur'],
              pattern: /^1[3456789]\d{9}$/
            }
          ]
          // note: [
          //   {
          //     required: true,
          //     message: '请输入设备备注',
          //     trigger: ['blur', 'change'],
          //   },
          // ],

        }

        activeInfo = {
          is_active: '',
          user_id: '',
          active_time: '',
          mobile: '',
          author: ''
        };

        @Watch('form', { deep: true })
        formChange (val: any) {
        }

        @Watch('hardwareSpecData', { deep: true })
        hardwareSpecDataChange (val: any) {
          this.form.hardware_spec_item = val.hardware_spec_item
        }

        @Watch('virtualCameraSpecData', { deep: true })
        virtualCameraSpecDataChange (val: any) {
          this.form.virtualCamera_spec_item = val.virtualCamera_spec_item
        }

        // 设备详情
        getDeviceDetail () {
          apiDeviceDetail(this.id).then((res: any) => {
            this.initHardwareSpecData(res.device_spec)
            this.initVirtualCameraSpecData(res.device_spec)
            this.form = res

            this.form.device_size_type = parseFloat(res.device_size_type)

            this.activeInfo = {
              is_active: res.is_active == 0 ? '未激活' : '已激活',
              active_time: res.activation_time,
              mobile: res.mobile,
              user_id: res.user_id,
              author: res.mobile
            }
          })
        }

        // 获取设备分类列表
        getDeviceCategoryList (): void {
          apiGetDeviceCategory({ pager_type: 1 }).then((res: any) => {
            this.deviceCategoryList = res.lists
          })
        }

        // “上一步”和“下一步”
        onNextStep (isNext = true) {
          switch (this.activeName) {
            case 'deviceBasic':
              this.activeName = 'hardware'
              break
            case 'hardware':
              this.activeName = isNext ? 'virtualCamera' : 'deviceBasic'
              break
            case 'virtualCamera':
              this.activeName = 'hardware'
              break
            case 'activeInfo':
              this.activeName = 'virtualCamera'
              break
          }
        }

        // 新增设备
        handleSave () {
          /* if (this.form.device_size_type !== 0) {
                this.form.device_size = this.form.device_size_type
              } */
          // TODO 增加所属机构手机号
          const sendData = this.form

          sendData.device_spec = {
            hardware_spec_item: this.form.hardware_spec_item,
            virtualCamera_spec_item: this.form.virtualCamera_spec_item
          }

          this.$refs.form.validate((valid: boolean, object: any) => {
            if (valid) {
              const loading = this.$loading({
                lock: true,
                text: '保存中...',
                spinner: 'el-icon-loading'
              })

              const api = this.id ? apiDeviceEdit(sendData) : apiDeviceAdd(sendData)
              api
                .then(() => {
                  this.$router.go(-1)
                })
                .finally(() => {
                  loading.close()
                })
            } else {
              const fieldsitem = this.requireFields.find((item) => {
                for (const value of item.fields) {
                  if (object[value]) {
                    this.$message.error(object[value][0].message)
                    return true
                  }
                }
              })
              fieldsitem && (this.activeName = fieldsitem.activeName)
              return false
            }
          })
        }

        // 初始化与硬件相关的参数
        initHardwareSpecData (data: any = {}) {
          // eslint-disable-next-line camelcase
          const hardware_spec_item = [
            {
              spec_type: 0,
              // 规格名
              spec_name: '',
              // 规格值
              spec_value: ''
            }
          ]
          const specData: any = {
            // eslint-disable-next-line camelcase
            hardware_spec_item: data.hardware_spec_item || hardware_spec_item
          }
          Object.assign(this.hardwareSpecData, specData)
          this.loading = false
        }

        // 初始化与虚拟相机相关的参数
        initVirtualCameraSpecData (data: any = {}) {
          // eslint-disable-next-line camelcase
          const virtualCamera_spec_item = [
            {
              spec_type: 1,
              // 规格名
              spec_name: '',
              // 规格值
              spec_value: ''
            }
          ]
          const specData: any = {
            virtualCamera_spec_item:
            // eslint-disable-next-line camelcase
                    data.virtualCamera_spec_item || virtualCamera_spec_item
          }
          Object.assign(this.virtualCameraSpecData, specData)
          this.loading = false
        }

        // 初始化
        initData () {
          if (!this.id) {
            apiDeviceNumber({}).then((resp: any) => {
              this.form.device_number = resp[0].device_number
            })
          }
        }

        // 获取设备尺寸
        getDeviceSize () {
          // 请求数据

          getDeviceSizeApi().then((resp: any) => {
            this.sizeList = resp
          })
        }

        getDeviceSupplier () {
          // 请求数据

          getDeviceSupplierApi().then((resp: any) => {
            this.supplierList = resp
          })
        }

        created () {
          this.getDeviceCategoryList()
          this.id = this.$route.query.id

          /* this.getDeviceSize()
              this.getDeviceSupplier() */

          if (this.id) {
            this.getDeviceDetail()
            this.initData()

            return
          }
          this.initHardwareSpecData()
          this.initVirtualCameraSpecData()
          // this.initData()
        }

        /**
         * 验证手机号
         * @param mobile
         */
        validatePhoneNumber (mobile: string) {
          const reg = /^1[3456789]\d{9}$/
          if (!reg.test(mobile)) {
            // 手机号码不符合规则
            return false
          }
          // 手机号码符合规则
          return true
        }
}
