
import {Component, Prop, Vue} from 'vue-property-decorator'
import PopoverInput from '@/components/popover-input.vue'
import MaterialSelect from '@/components/material-select/index.vue'
import {getAllDeviceParameters} from "@/api/imagetru3d";

@Component({
  components: {
    MaterialSelect,
    PopoverInput,
  },
})
export default class AddHardware extends Vue {
  @Prop() value: any
  @Prop() virtualCameraSpecData!: any
  options: any = []

  get sepcItem() {
    return this.value.virtualCamera_spec_item
  }

  created() {
    getAllDeviceParameters().then((res) => {
      this.options = res
    })
  }

  optionFilter(item: any): boolean {
    return this.value.virtualCamera_spec_item.some((j: any) =>
        item.name === j.spec_name
    )
  }

  // 添加参数项
  addSpecItem() {
    this.sepcItem.push({
      spec_type: 1,
      spec_name: '',
      spec_value: '',
    })
  }

  // 移除参数项
  removeSpecItem(index: number) {
    if (this.sepcItem.length <= 1)
      return this.$message.error('至少有一个参数项')
    this.sepcItem.splice(index, 1)
  }

  checkValue(index: number) {
    const name = this.sepcItem[index].spec_name
    console.log(name)
    this.sepcItem.forEach((item: any, idx: number) => {
      if (item.spec_name && index != idx && item.spec_name == name) {
        this.sepcItem[index].spec_name = ''
        this.$message({
          message: '已存在相同规格值',
          type: 'warning',
        })
      }
    })
  }
}
