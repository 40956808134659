
import { Component, Prop, Vue } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'

import BtnUpload from '@/components/upload/BtnUpload.vue'
import { getDeviceSizeApi, getDeviceSupplierApi, setDeviceSizeApi, setDeviceSupplierApi } from '@/api/imagetru3d'
import Template from '@/views/shop/pages/template.vue'

@Component({
  components: {
    Template,
    MaterialSelect,
    BtnUpload
  }
})
export default class AddDeviceInfo extends Vue {
  @Prop() value: any
  @Prop() sizeList: any
  @Prop() supplier: any
  @Prop({ default: () => ({}) })
  lists: any

  form = {}
  visible_size = false
  labelPosition= 'top'
  visible_supplier = false // 生产厂家的弹窗
  sizeRes = [];
  supplierRes = [];
  sizeValidateForm = {
    size_name: ''
  }

  // eslint-disable-next-line camelcase
  size_name = ''
  supplier_name = ''
  merchant_mobile=''

  submitForm (str :any) {
    // eslint-disable-next-line eqeqeq
    if (str == 'sizeValidateForm') {
      const data = {
        name: this.size_name,
        sort: 1
      }
      const api = setDeviceSizeApi(data)

      api.then((response:any) => {
        this.deviceSizeList()
      })
      this.visible_size = false // 保存后关闭弹窗
    }

    if (str == 'supplierValidateForm') {
      const data = {
        name: this.supplier_name,
        sort: 1
      }
      const api = setDeviceSupplierApi(data)

      api.then((response:any) => {
        this.deviceSupplierList()
      })

      this.visible_supplier = false // 保存后关闭弹窗
    }
  }

  // 重置表格
  resetForm (str:string) {
    if (str == 'sizeValidateForm') {
      this.size_name = ''
    }

    if (str == 'supplierValidateForm') {
      this.supplier_name = ''
    }
  }

  get deviceCategoryList () {
    return this.lists || []
  }

  /**
   * 设置设备尺寸参数
   * @param val
   */
  setValueSupplierId (val: any) {
    this.value.supplier_id = val

    let obj :any
    // 这里的userList就是上面遍历的数据源
    obj = this.supplierRes.find((item: any) => { // 这里的userList就是上面遍历的数据源
      if (item.id == val) {
        return item.name
      }
    })

    this.value.supplier_name = obj.name // 赋值厂家名称
    console.log(this.value)
  }

  handleSuccess (response: any) {
    const { url } = response
    this.value.mask = url
  }

  deviceSizeList () {
    getDeviceSizeApi().then((resp:any) => {
      this.sizeRes = resp
    })
  }

  deviceSupplierList () {
    getDeviceSupplierApi().then((resp:any) => {
      this.supplierRes = resp
    })
  }

  created () {
    this.deviceSizeList()
    this.deviceSupplierList()
  }
}
