
import { Component, Vue } from 'vue-property-decorator'
import TemplateDetail from '@/components/shop/template-select/detail.vue'
import { apiThemePageAdd } from '@/api/shop'

@Component({
    components: {
        TemplateDetail,
    },
})
export default class PagesTemplate extends Vue {
    handleSelect(data: any) {
        apiThemePageAdd(data).then((res) => {
            this.$router.push({
                path: '/decorate/index',
                query: { id: res.id },
            })
        })
    }
}
