
import {Component, Prop, Vue} from 'vue-property-decorator'



@Component({
  components: {


  },
})
export default class AddHardware extends Vue {
  @Prop() value: any
  @Prop() active: any


  created() {

  }






}
